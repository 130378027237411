import React, { createContext, useEffect, useState } from "react";
import { injected } from "src/connectors";
import { useWeb3React } from "@web3-react/core";
import * as anchor from "@project-serum/anchor";
import { ACTIVE_NETWORK, REACT_APP_CANDY_MACHINE_ID,REACT_APP_SOLANA_RPC_HOST, REACT_APP_CANDY_START_DATE,   REACT_APP_TREASURY_ADDRESS,REACT_APP_CANDY_MACHINE_CONFIG,REACT_APP_SOLANA_NETWORK } from "src/constants";
import {
  getBalanceOf,
  getContract,
  getWeb3ContractObject,
  getWeb3Obj,
  swichNetworkHandler,
} from "src/utils";
import { GalacticEagleContract } from "src/constants";
import GalacticEagleABI from "src/ABI/GalacticEagleABI.json";
import axios from "axios";

const treasury = new anchor.web3.PublicKey(
  REACT_APP_TREASURY_ADDRESS
);

const config = new anchor.web3.PublicKey(
  REACT_APP_CANDY_MACHINE_CONFIG
);

const candyMachineId = new anchor.web3.PublicKey(
  REACT_APP_CANDY_MACHINE_ID
);

const network = REACT_APP_SOLANA_NETWORK; // as WalletAdapterNetwork;

const rpcHost = REACT_APP_SOLANA_RPC_HOST;

const connection = new anchor.web3.Connection(rpcHost);
const txTimeout = 30000; // milliseconds (confirm this works for your project)
const startDateSeed = parseInt(REACT_APP_CANDY_START_DATE, 10);


export const UserContext = createContext();

const setSession = (userAddress) => {
  if (userAddress) {
    sessionStorage.setItem("userAddress", userAddress);
  } else {
    sessionStorage.removeItem("userAddress");
  }
};

export default function AuthProvider(props) {
  const { activate, account, library, deactivate, chainId } = useWeb3React();
  const [nftPrice, setNftPrice] = useState(0);
  const [hasFinalSaleStarted, setHasFinalSaleStarted] = useState(false);
  // const [reservedClaimed, setReservedClaimed] = useState(1);
  // const [RESERVED_NFT, setRESERVED_NFT] = useState(1);
  const [MAX_NFT_SUPPLY, setMAX_NFT_SUPPLY] = useState(0);
  const [totalSupply, setTotalSupply] = useState(0);
  const [MAX_MINT, setMAX_MINT] = useState(0);
  const [MAX_NFT_WALLET, setMAX_NFT_WALLET] = useState(0);
  const [balanceOfValue, setBalanceOfValue] = useState(0);
  const [publicSaleStartTimestamp, setPublicSaleStartTimestamp] = useState(0);
  const [adminWalletAddress, setAdminWalletAddress] = useState("");
  const [allNftList, setallNftList] = useState([]);
  const [userNFTList, setUserNFTList] = useState([]);
  let data = {
    candyMachineId,
    config,
    connection,
    startDate: startDateSeed,
    treasury,
    txTimeout,
    network,
    nftPrice,
    allNftList,
    balanceOfValue,
    adminWalletAddress,
    // reservedClaimed,
    // RESERVED_NFT,
    MAX_NFT_SUPPLY,
    totalSupply,
    MAX_MINT,
    MAX_NFT_WALLET,
    hasFinalSaleStarted,
    publicSaleStartTimestamp,
    userNFTList,
    getCurrentMintingDetails: () => getCurrentMintingDetails(),
    updateUser: (account) => {
      setSession(account);
    },
    connectWallet: () => {
      activate(injected, undefined, true).catch((error) => {
        if (error) {
          activate(injected);
        }
      });
    },
  };

  const userNFTListHadler = async (balanceOf, cancelTokenSource) => {
    setUserNFTList([]);
    const contract = getContract(
      GalacticEagleContract,
      GalacticEagleABI,
      library,
      account
    );

    try {
      for (let i = 0; i < balanceOf; i++) {
        const id = await contract.tokenOfOwnerByIndex(account, i);
        const filter = await contract.tokenURI(id.toString());
        const res = await axios.get(filter, {
          cancelToken: cancelTokenSource && cancelTokenSource.token,
        });
        if (res.status === 200) {
          setUserNFTList((prev) => [
            ...prev,
            { id: id.toString(), nfdData: res.data },
          ]);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();

    if (balanceOfValue > 0) {
      userNFTListHadler(balanceOfValue, cancelTokenSource);
    }
    return () => {
      cancelTokenSource.cancel();
    };
  }, [balanceOfValue, account]); //eslint-disable-line

  const allNFTListHandler = async (txaCount) => { //eslint-disable-line no-unused-vars
    const contract = await getWeb3ContractObject(
      GalacticEagleABI,
      GalacticEagleContract
    );
    try {
      for (let i = 1; i <= parseInt(txaCount); i++) {
        const tokenURI = await contract.methods.tokenURI(i.toString()).call();
        const res = await axios.get(tokenURI);
        if (res.status === 200) {
          setallNftList((prev) => [
            ...prev,
            { id: i.toString(), nfdData: res.data },
          ]);
        }
      }
    } catch (error) {
      setallNftList([]);
      console.log("ERROR", error);
    }
  };
  useEffect(() => {
    if (totalSupply > 0) {
      // allNFTListHandler(totalSupply);
    }
  }, [totalSupply]); //eslint-disable-line

  const getContractDetails = async () => {
    try {
      const web3 = await getWeb3Obj();
      const contractObj = await getWeb3ContractObject(
        GalacticEagleABI,
        GalacticEagleContract
      );
      const adminAccount = await contractObj.methods.owner().call();
      setAdminWalletAddress(adminAccount);
      const hasFinalSaleStarted = await contractObj.methods
        .hasFinalSaleStarted()
        .call();
      setHasFinalSaleStarted(hasFinalSaleStarted);
      console.log("contractObj", contractObj);
      const publicSaleStartTimestamp = await contractObj.methods
        .publicSaleStartTimestamp()
        .call();
      setPublicSaleStartTimestamp(publicSaleStartTimestamp);

      const MAX_MINT = await contractObj.methods.MAX_MINT().call();
      setMAX_MINT(Number(MAX_MINT));

      const MAX_NFT_WALLET = await contractObj.methods.MAX_NFT_WALLET().call();
      setMAX_NFT_WALLET(Number(MAX_NFT_WALLET));

      if (hasFinalSaleStarted) {
        const NFT_PRICE = await contractObj.methods.NFT_PRICE().call();
        const getNFTPrice = await web3.utils.fromWei(NFT_PRICE.toString());
        setNftPrice(getNFTPrice);
      } else {
        const RESERVE_PRICE = await contractObj.methods.RESERVE_PRICE().call();
        const getRESERVE_PRICE = await web3.utils.fromWei(
          RESERVE_PRICE.toString()
        );
        setNftPrice(getRESERVE_PRICE);
        // const reservedClaimed = await contractObj.methods
        //   .reservedClaimed()
        //   .call();
        // const RESERVED_NFT = await contractObj.methods.RESERVED_NFT().call();
        // setReservedClaimed(10);
        // setRESERVED_NFT(20);
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  useEffect(() => {
    if (account) {
      getBalanceOfFun();
    }
  }, [account]); //eslint-disable-line

  async function getBalanceOfFun() {
    setBalanceOfValue(
      await getBalanceOf(GalacticEagleABI, GalacticEagleContract, account)
    );
  }

  const getCurrentMintingDetails = async () => {
    const contractObj = await getWeb3ContractObject(
      GalacticEagleABI,
      GalacticEagleContract
    );
    if (account) {
      getBalanceOfFun();
    }
    const MAX_NFT_SUPPLY = await contractObj.methods.MAX_NFT_SUPPLY().call();
    setMAX_NFT_SUPPLY(Number(MAX_NFT_SUPPLY));
    const totalSupply = await contractObj.methods.totalSupply().call();
    setTotalSupply(Number(totalSupply));
  };

  useEffect(() => {
    getContractDetails();
    getCurrentMintingDetails();
  }, []); //eslint-disable-line

  useEffect(() => {
    if (account && chainId) {
      if (chainId !== ACTIVE_NETWORK) {
        if (window.ethereum) {
          swichNetworkHandler();
        }
      }
    }
  }, [chainId, account]); //eslint-disable-line

  useEffect(() => {
    const userAddress = window.sessionStorage.getItem("userAddress");
    if (userAddress) {
      data.connectWallet();
    }
  }, []); //eslint-disable-line

  useEffect(() => {
    data.updateUser(account);
  }, [account]); //eslint-disable-line

  useEffect(() => {
    if (!account) {
      deactivate();
    }
  }, [account]); //eslint-disable-line

  return (
    <UserContext.Provider value={data}>{props.children}</UserContext.Provider>
  );
}
