export const NetworkContextName = "Kovan Test Network";
export const ACTIVE_NETWORK = 42;
export const GalacticEagleContract =
  "0xD69F29A0D04344B9e0c6c5F1937B26649491c200";
export const baseURI =
  "https://ipfs.io/ipfs/QmdP6VwefyuVTz3k7n2AcPv16HxJ3fz3zN23vAkJY9S6ST";

export const RPC_URL =
  "https://kovan.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161";

export const NetworkDetails = [
  {
    chainId: "0x2A",
    chainName: "Kovan Test Network",
    nativeCurrency: {
      name: "Kovan Test Network",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ["https://kovan.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"],
    blockExplorerUrls: ["https://kovan.etherscan.io"],
  },
];

export const countDownDate = new Date("Nov 19, 2021 08:00:00 PST").getTime();
export const countDownDate1 = countDownDate.toLocaleString("en-US", {
  timeZone: "America/New_York",
});


 export const REACT_APP_CANDY_MACHINE_CONFIG=`8Eu4RKjs1R13nuK496vUfefG4wnJvSAdqDpi34iS3ga7`
 export const REACT_APP_CANDY_MACHINE_ID="5fkdCNHYDWVxMM43ZavDd2FpUUemcAUZduAJBfnDsBxk"
 export const REACT_APP_TREASURY_ADDRESS="54mRMjrXNzfUpnBdAEUKAu2z2XLgApUpi35nWW7PxQaJ"
 export const REACT_APP_CANDY_START_DATE=1638946492

 export const REACT_APP_SOLANA_NETWORK="devnet"
 export const REACT_APP_SOLANA_RPC_HOST=`https://explorer-api.devnet.solana.com`